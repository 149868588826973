import { useContext } from 'react'

import { useMutation } from '@tanstack/react-query'

import { RETURN_TYPE, TEXTS } from '~/utils/allConstants/Constants'

import { NotificationContext } from '../components/Notification'
import { APIErrorRes } from '../types/APIErrorRes.model'
import { ReviewReturnCSR, ReviewReturnCSS } from '../types/Approval.model'
import {
  DenialReasons,
  InternalReasons,
  Warehouse,
} from '../types/Return.model'

import axiosInstance from './axiosInstance'

const { SOMETHING_WENT_WRONG } = TEXTS

export const useAssignToMeMutation = () => {
  const assignToMeMutation = useMutation((id: string) => {
    return axiosInstance
      .post(`/v1/returns/${id}/assignToMe`)
      .then(({ data }) => {
        return data
      })
      .catch(({ response }) => {
        return Promise.reject(response)
      })
  })
  return assignToMeMutation
}

export const fetchDenialReasons = () => {
  return axiosInstance
    .get<DenialReasons>(`/v1/returns/directory/denialReasons`)
    .then(({ data }) => {
      return {
        ...data,
      }
    })
}

export const fetchWarehouse = () => {
  return axiosInstance.get<Warehouse[]>(`/v1/warehouse`).then(({ data }) => {
    return {
      ...data,
    }
  })
}

export const fetchInternalReasons = () => {
  return axiosInstance
    .get<InternalReasons>(`/v1/returns/directory/internalReasons`)
    .then(({ data }) => {
      return {
        ...data,
      }
    })
}

export const useReviewReturnCSR = (id: string) => {
  const alert = useContext(NotificationContext)
  const reviewReturnCsrMutation = useMutation(
    (data: ReviewReturnCSR) => {
      return axiosInstance.post(
        `/v1/returns/${id}/review`,
        data.variable.data,
        {
          params: {
            submit: data.variable.submit,
          },
        }
      )
    },
    {
      onSuccess: () => {
        alert.success('Return review research completed Successfully')
      },
      onError: (e: APIErrorRes) => {
        alert.error(e.response.data.message || SOMETHING_WENT_WRONG)
      },
    }
  )

  return reviewReturnCsrMutation
}

export const useApprovalReturn = (id: string, type: string) => {
  const alert = useContext(NotificationContext)

  let url = `/v1/returns/${id}/approve`
  let successMessage = RETURN_TYPE.CSS.msg

  if (type === RETURN_TYPE.CARRIER.type) {
    url = `/v1/returns/${id}/${RETURN_TYPE.CARRIER.type}/approve`
    successMessage = RETURN_TYPE.CARRIER.msg
  }
  if (type === RETURN_TYPE.CREDIT.type) {
    url = `/v1/returns/${id}/${RETURN_TYPE.CREDIT.type}/approve`
    successMessage = RETURN_TYPE.CREDIT.msg
  }

  const approvalReturnMutation = useMutation(
    (data: ReviewReturnCSS | ReviewReturnCSR) => {
      return axiosInstance.post(url, data.variable.data, {
        params: {
          submit: data.variable.submit,
        },
      })
    },
    {
      onSuccess: () => {
        alert.success(successMessage)
      },
      onError: (e: APIErrorRes) => {
        alert.error(e.response.data.message || SOMETHING_WENT_WRONG)
      },
    }
  )

  return approvalReturnMutation
}

export const useUnassign = () => {
  const unassignMutation = useMutation((id: string) => {
    return axiosInstance
      .post(`/v1/returns/${id}/unassign`)
      .then(({ data }) => {
        return data
      })
      .catch(({ response }) => {
        return Promise.reject(response)
      })
  })
  return unassignMutation
}
