import { FC, ReactElement, useState } from 'react'

import { LoadingButton } from '@mui/lab'
import { Grid } from '@mui/material'

import BackButton from '~/components/common/backButton/BackButton'
import { TEXTS } from '~/utils/allConstants/Constants'

const { EXPORT_TO_PDF } = TEXTS

interface Props {
  isData: boolean
  handlePdfExport: any
  roleCheck: boolean
  isCanceledOrder?: boolean
}

const Controls: FC<Props> = ({
  isData,
  handlePdfExport,
  roleCheck,
  isCanceledOrder,
}): ReactElement => {
  const [isLoading, setIsLoading] = useState(false)

  const onExportClick = () => {
    setIsLoading(true)
    handlePdfExport().then(() => setIsLoading(false))
  }

  return (
    <Grid container mt={3}>
      <Grid item xs={12} className="action-buttons-wrapper">
        <BackButton />
        {isData && roleCheck && !isCanceledOrder && (
          <LoadingButton
            className={'action-button'}
            variant={'contained'}
            sx={{
              mt: 1,
            }}
            loading={isLoading}
            onClick={onExportClick}
          >
            {EXPORT_TO_PDF}
          </LoadingButton>
        )}
      </Grid>
    </Grid>
  )
}

export default Controls
