import { FC, useCallback, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'

import { yupResolver } from '@hookform/resolvers/yup'
import InfoIcon from '@mui/icons-material/Info'
import { LoadingButton } from '@mui/lab'
import {
  Box,
  Button,
  FormHelperText,
  Grid,
  IconButton,
  Link,
  Typography,
} from '@mui/material'

import CustomTooltip from '~/components/common/customTooltip/CustomTooltip'
import DataGrid from '~/components/UI/DataGrid/DataGrid'
import useDataGridStore, {
  DataGridProvider,
} from '~/components/UI/DataGrid/DataGridContext'
import { useAuth } from '~/services/auth'
import { useCreateOrderReturn } from '~/services/returns'
import { OrderDetailsData } from '~/types/OrderDetailsData.model'
import { DATE_STRING_OPTIONS, TEXTS } from '~/utils/allConstants/Constants'
import { fileValidation, formatDate } from '~/utils/helpers/helperFunctions'

import ModalContainer from '../UI/ModalContainer'
import ModalHeader from '../UI/ModalHeader'
import { ControllerStyledTextField } from '../UI/ModalInputs'

import variables from '~/theme/scss/_variables.module.scss'

const { acrColorPrimary } = variables
const {
  SUBMIT_RETURN_REQUEST,
  CREDIT_REQUEST_REVIEW,
  RETURN_REQUEST_REVIEW,
  CLOSE,
  ORDER_NUMBER,
  INVOICE_NUMBER,
  PO,
  INVOICED_PRICE,
  QUOTED_PRICE,
  EXTENDED_AMOUNT,
} = TEXTS

type ReturnItems = { reason: string; quantity: number; productId: string }
interface Props {
  orderInfo: OrderDetailsData | any
  returnItems: ReturnItems[]
  closeModal?: () => void
  isIncorrectPriceItem: boolean
  zendeskTicketLink: string
}

const getUserFields = (isIncorrectPriceItem: boolean) => {
  return [
    {
      id: 'lineNumber',
      label: 'Line',
      minWidth: 20,
      sort: true,
    },
    {
      id: 'productId',
      label: 'Item Number',
      minWidth: 50,
      sort: true,
    },
    {
      id: 'quantity',
      label: isIncorrectPriceItem ? 'Credit Qty' : 'Return Qty',
      minWidth: 50,
      sort: true,
    },
    {
      id: 'reason',
      label: 'Reason',
      minWidth: 100,
      sort: true,
    },
  ]
}

const ReturnModalBody: FC<Props> = ({
  orderInfo,
  closeModal,
  returnItems,
  isIncorrectPriceItem,
  zendeskTicketLink,
}) => {
  const navigate = useNavigate()
  const createReturnOrder = useCreateOrderReturn()
  const [isLoading, setIsLoading] = useState(false)
  const source = useCallback(() => {
    return {
      items: returnItems.map((val: any) => ({
        ...val,
        id: val.lineNumber,
      })),
    }
  }, [returnItems])

  const { data: user } = useAuth()

  const modalHeaderData = [
    {
      label: ORDER_NUMBER,
      value: orderInfo.orderNumber,
    },
    {
      label: INVOICE_NUMBER,
      value: orderInfo.invoiceNumber,
    },
    {
      label: PO,
      value: orderInfo.customerNumber,
    },
  ]

  const schema = yup
    .object({
      comment: yup.string().required('Comment is Required'),
      file: yup
        .mixed()
        .test(
          'is-damaged-product',
          'Damaged product image is required',
          (files) => {
            if (returnItems.some((e: any) => e.reason === 'Damaged Product')) {
              if (!files) {
                return false
              }
            }
            return true
          }
        )
        .test('validation', 'error', fileValidation),
    })
    .required()

  const { handleSubmit, control, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      comment: '',
      file: '',
    },
  })

  const handleSendReturn = (formData: {
    comment: string
    file: File | string
  }) => {
    setIsLoading(true)
    const returnData = returnItems.map((item: ReturnItems, index: number) => {
      return {
        ...returnItems[index],
        reason: item.reason.replaceAll(' ', '_').toUpperCase(),
      }
    })

    const data = new FormData()
    Object.values(formData.file).map((file: File) => data.append('files', file))
    const apiData: any = {
      orderId: orderInfo.orderNumber,
      items: returnData,
      comment: formData.comment,
    }
    if (isIncorrectPriceItem) {
      apiData.url = zendeskTicketLink
    }
    data.append('body', JSON.stringify(apiData))
    createReturnOrder.mutate(data, {
      onSuccess: () => {
        setIsLoading(false)
        closeModal()
        reset({ comment: '', file: '' })
        navigate('/returns/open-returns')
      },
      onError: () => {
        setIsLoading(false)
        closeModal()
        reset({ comment: '', file: '' })
      },
    })
  }

  const { setTableProps, tableProps } = useDataGridStore()

  const actions = isIncorrectPriceItem
    ? [
        {
          header: () => (
            <Grid
              container
              spacing={2}
              sx={{ minWidth: 360, alignItems: 'center' }}
            >
              <Grid item sx={{ minWidth: 120 }}>
                {INVOICED_PRICE}
              </Grid>
              <Grid item sx={{ minWidth: 120 }}>
                {QUOTED_PRICE}
              </Grid>
              <Grid item sx={{ minWidth: 120 }}>
                {EXTENDED_AMOUNT}
              </Grid>
            </Grid>
          ),
          render: ({ invoicedPrice, quotedPrice, extendedAmount }: any) => {
            return (
              <Grid
                container
                spacing={2}
                className="content-wrapper"
                sx={{ minWidth: 360, alignItems: 'center' }}
              >
                <Grid item sx={{ minWidth: 120 }}>
                  ${invoicedPrice}
                </Grid>
                <Grid item sx={{ minWidth: 120 }}>
                  ${quotedPrice}
                </Grid>
                <Grid item sx={{ minWidth: 120 }}>
                  ${extendedAmount}
                </Grid>
              </Grid>
            )
          },
        },
      ]
    : []

  useEffect(() => {
    setTableProps({
      name: 'Return Data',
      source,
      actions,
      fields: getUserFields(isIncorrectPriceItem),
    })
  }, [source, setTableProps, returnItems])

  return (
    <Box
      sx={{
        position: 'relative',
        top: '1%',
        textAlign: 'left',
        p: 2,
      }}
    >
      <ModalHeader
        data={modalHeaderData}
        heading={
          isIncorrectPriceItem ? CREDIT_REQUEST_REVIEW : RETURN_REQUEST_REVIEW
        }
      />
      {Object.keys(tableProps).length > 0 && <DataGrid />}
      {isIncorrectPriceItem && (
        <Grid container>
          <Grid item xs={12} pt={2}>
            <Box
              sx={{
                fontWeight: 'bold',
                marginRight: 1,
                display: 'inline-flex',
              }}
            >
              Link:
            </Box>
            <Link href={zendeskTicketLink} target={'_blank'}>
              {zendeskTicketLink}
            </Link>
          </Grid>
        </Grid>
      )}
      <ModalContainer
        author={`${user.firstName} ${user.lastName}`}
        createdAt={formatDate(Date.now(), DATE_STRING_OPTIONS)}
        InputComponent={
          <ControllerStyledTextField
            name={'comment'}
            control={control}
            placeholder={'Your reason...'}
          />
        }
        attachments={
          <Controller
            name="file"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <>
                <label htmlFor="upload-file">
                  <input
                    style={{ display: 'none', cursor: 'pointer' }}
                    multiple
                    id="upload-file"
                    type="file"
                    onChange={(e) =>
                      field.onChange({
                        target: {
                          value: e.target.files,
                          name: field.name,
                        },
                      })
                    }
                  />
                  <Typography
                    color={acrColorPrimary}
                    sx={{ ':hover': { cursor: 'pointer' } }}
                  >
                    <>
                      {field.value.length != 0
                        ? `${Array.from(field.value).map(
                            (file: any) => file.name
                          )}`
                        : 'Attachment Files...'}
                      <CustomTooltip
                        title={
                          <ul
                            style={{
                              margin: 0,
                              paddingLeft: '15px',
                              lineHeight: 2,
                            }}
                          >
                            File Requirements:
                            <li>
                              File accepts pdf, jpeg, png, jpg and gif types
                            </li>
                            <li>Single image cannot be more than 10mb</li>
                            <li>All images cannot be more than 50mb</li>
                          </ul>
                        }
                      >
                        <IconButton>
                          <InfoIcon />
                        </IconButton>
                      </CustomTooltip>
                    </>
                  </Typography>
                </label>
                {!!error && (
                  <FormHelperText error={!!error}>
                    {!!error ? error.message : ''}
                  </FormHelperText>
                )}
              </>
            )}
          />
        }
      />
      <Grid container>
        <Grid item xs={12} display={'flex'} justifyContent={'center'}>
          <LoadingButton
            loading={isLoading}
            variant="contained"
            sx={{
              borderRadius: 0,
              margin: '5px',
            }}
            onClick={handleSubmit(handleSendReturn)}
          >
            {SUBMIT_RETURN_REQUEST}
          </LoadingButton>
          <Button
            variant={'outlined'}
            sx={{
              borderRadius: 0,
              paddingLeft: 5,
              paddingRight: 5,
              margin: '5px',
              fontSize: '14px',
              width: '140px',
            }}
            onClick={closeModal}
          >
            {CLOSE}
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}

// eslint-disable-next-line react/display-name
export default (props: Props) => (
  <DataGridProvider>
    <ReturnModalBody {...props} />
  </DataGridProvider>
)
