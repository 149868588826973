import CustomTooltip from '~/components/common/customTooltip/CustomTooltip'
import { Fields } from '~/types/Fields.model'
import { TEXTS } from '~/utils/allConstants/Constants'
import { formatCurrency } from '~/utils/helpers/helperFunctions'

import '~/components/Approval/components/ReturnRequest.scss'

const {
  N_A,
  LINE,
  ITEM,
  QTY,
  RETURN_REASON_CODE,
  INTERNAL_REASON_CODE,
  REVIEWER_COMMENT,
  INVOICED_PRICE,
  QUOTED_PRICE,
  EXTENDED_AMOUNT,
} = TEXTS

export const fields: Fields[] = [
  {
    id: 'lineNumber',
    label: LINE,
    minWidth: 30,
  },
  {
    id: 'itemNo',
    label: ITEM,
    minWidth: 80,
  },
  {
    id: 'quantity',
    label: QTY,
    minWidth: 50,
  },
  {
    id: 'reason',
    label: RETURN_REASON_CODE,
    minWidth: 100,
  },
  {
    id: 'internalReason',
    label: INTERNAL_REASON_CODE,
    minWidth: 50,
  },
  {
    id: 'comment',
    label: REVIEWER_COMMENT,
    minWidth: 50,
    format: (_, row) => {
      return row.comment ? (
        <CustomTooltip title={row.comment}>
          <span className="span">{row.comment}</span>
        </CustomTooltip>
      ) : (
        N_A
      )
    },
  },
]

export const creditFields: Fields[] = [
  {
    id: 'lineNumber',
    label: LINE,
    minWidth: 30,
  },
  {
    id: 'itemNo',
    label: ITEM,
    minWidth: 80,
  },
  {
    id: 'quantity',
    label: QTY,
    minWidth: 50,
  },
  {
    id: 'reason',
    label: RETURN_REASON_CODE,
    minWidth: 100,
  },
  {
    id: 'internalReason',
    label: INTERNAL_REASON_CODE,
    minWidth: 50,
  },
  {
    id: 'invoicedPrice',
    label: INVOICED_PRICE,
    minWidth: 50,
    format: (_, row) => formatCurrency(row.invoicedPrice),
  },
  {
    id: 'quotedPrice',
    label: QUOTED_PRICE,
    minWidth: 50,
    format: (_, row) => formatCurrency(row.quotedPrice),
  },
  {
    id: 'extendedAmount',
    label: EXTENDED_AMOUNT,
    minWidth: 50,
    format: (_, row) => formatCurrency(row.extendedAmount),
  },
  {
    id: 'comment',
    label: REVIEWER_COMMENT,
    minWidth: 50,
    format: (_, row) => {
      return row.comment ? (
        <CustomTooltip title={row.comment}>
          <span className="span">{row.comment}</span>
        </CustomTooltip>
      ) : (
        N_A
      )
    },
  },
]
