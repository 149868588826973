import { FC, ReactElement } from 'react'
import { Control, UseFormHandleSubmit } from 'react-hook-form'

import { LoadingButton } from '@mui/lab'
import { Box, Grid, Link } from '@mui/material'

import BackButton from '~/components/common/backButton/BackButton'
import CreatedReturnInfo from '~/components/common/createdReturnInfo/CreatedReturnInfo'
import NoDataFound from '~/components/common/noDataFound/NoDataFound'
import ControlledStyledTextField from '~/components/Form/StyledTextField'
import DataGrid from '~/components/UI/DataGrid/DataGrid'
import {
  Options,
  ReturnRequestApproval,
  SubmitApproval,
} from '~/types/Approval.model'
import { TEXTS } from '~/utils/allConstants/Constants'

import './ApprovalReturnRequestContainer.scss'
import variables from '~/theme/scss/_variables.module.scss'

const { DOWNLOAD_ATTACHMENTS, APPROVER_COMMENT, COMPLETE_APPROVAL, SAVE } =
  TEXTS
const { acrColorCyanWhite } = variables

type Props = {
  data: ReturnRequestApproval
  handleAttachment: () => void
  isLoading: boolean
  options: Options
  tableProps: any
  control: Control<any>
  handleSubmit: UseFormHandleSubmit<SubmitApproval>
  handleSave: (data: SubmitApproval) => void
  isSaveLoading: boolean
  isSubmitLoading: boolean
  handleSubmitApproval: (data: SubmitApproval) => void
}

const ApprovalReturnRequestContainer: FC<Props> = ({
  data,
  handleAttachment,
  isLoading,
  options,
  tableProps,
  control,
  handleSubmit,
  handleSave,
  isSaveLoading,
  isSubmitLoading,
  handleSubmitApproval,
}): ReactElement => {
  const isDataAvailable = !!data
  return (
    <Box sx={{ px: { xs: 2, md: 3 } }}>
      <Box>
        <Grid container mt={3} sx={{ alignItems: 'center' }}>
          <Grid item xs={12} className="action-buttons-wrapper">
            <BackButton />
            {isDataAvailable && Object.keys(data.assets).length > 0 && (
              <LoadingButton
                loading={isLoading}
                className={'action-button'}
                variant={'contained'}
                onClick={handleAttachment}
              >
                {DOWNLOAD_ATTACHMENTS}
              </LoadingButton>
            )}
          </Grid>
        </Grid>
        {isDataAvailable ? (
          <Box mt={3}>
            <CreatedReturnInfo pageHeading={options.name} returnData={data} />
            <Box sx={{ marginBottom: '20px' }}>
              {Object.keys(tableProps).length > 0 && <DataGrid />}
            </Box>
            {data.isCreditReturn && (
              <Box bgcolor={acrColorCyanWhite} sx={{ p: 2, mb: 2 }}>
                <Box component={'span'} sx={{ fontWeight: 'bold', mr: 1 }}>
                  Link:
                </Box>
                <Link
                  sx={{ wordWrap: 'break-word' }}
                  href={data.url}
                  target={'_blank'}
                >
                  {data.url}
                </Link>
              </Box>
            )}
            {(data.customStatus === 'REOPENED' ||
              data.currentGroup === 'LC' ||
              data.isCreditReturn) && (
              <Grid
                container
                sx={{ backgroundColor: acrColorCyanWhite }}
                mb={2}
              >
                <Grid item xs={12} sm={6} md={6} px={2} mt={2}>
                  <ControlledStyledTextField
                    name={'approverComment'}
                    label={APPROVER_COMMENT}
                    control={control}
                    className="approver-comment"
                  />
                </Grid>
              </Grid>
            )}
          </Box>
        ) : (
          <NoDataFound />
        )}
      </Box>
      <Box className="bottom-buttons-wrapper">
        <LoadingButton
          variant={'contained'}
          onClick={handleSubmit(handleSave)}
          loading={isSaveLoading}
          className="action-button"
        >
          {SAVE}
        </LoadingButton>
        <LoadingButton
          variant={'contained'}
          loading={isSubmitLoading}
          onClick={handleSubmit(handleSubmitApproval)}
          className="action-button"
        >
          {COMPLETE_APPROVAL}
        </LoadingButton>
      </Box>
    </Box>
  )
}

export default ApprovalReturnRequestContainer
