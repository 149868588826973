import { Fields } from '~/types/Fields.model'
import { TEXTS } from '~/utils/allConstants/Constants'

const { RETURN_REASON_CODE, LINE, ITEM, QTY } = TEXTS

export const fields: Fields[] = [
  {
    id: 'lineNumber',
    label: LINE,
    minWidth: 30,
  },
  {
    id: 'itemNo',
    label: ITEM,
    minWidth: 80,
  },
  {
    id: 'quantity',
    label: QTY,
    minWidth: 50,
  },
  {
    id: 'reason',
    label: RETURN_REASON_CODE,
    minWidth: 100,
  },
]
