import {
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Button, Grid, Typography } from '@mui/material'

import BackButton from '~/components/common/backButton/BackButton'
import NoDataFound from '~/components/common/noDataFound/NoDataFound'
import NoGridStyledTextField from '~/components/Form/StyledTextField'
import DataGrid from '~/components/UI/DataGrid/DataGrid'
import useDataGridStore, {
  DataGridProvider,
} from '~/components/UI/DataGrid/DataGridContext'
import { InvoiceDetailsData } from '~/types/InvoiceDetailsData.model'
import { OrderDetailsData } from '~/types/OrderDetailsData.model'
import {
  OrderReturnItems,
  ReturnItems,
  UpdateReturnData,
} from '~/types/Return.model'
import CustomModal from '~/UI/modals/CustomModal'
import ReturnModalBody from '~/UI/modals/modalBodies/returnModalBody/ReturnModalBody'
import {
  LINK_PATTERN,
  REASONS_KEY,
  TEXTS,
  VALID_LINK_ALERT,
} from '~/utils/allConstants/Constants'
import { getArrayFromLocalStorage } from '~/utils/helpers/helperFunctions'

import ReturnDetailInput, { ReturnDetailInputHeader } from './ReturnDetailInput'

import variables from '~/theme/scss/_variables.module.scss'

const { acrColorCyanWhite, acrColorGrey } = variables
const { ORDER_NUMBER, CONTINUE, INVOICE_NUMBER, LINK, INCORRECT_PRICE } = TEXTS

type Fields = {
  id: string
  label: string
  minWidth: number
}

type MultiReturn = {
  item: OrderReturnItems[]
}

type Options = {
  id: string
  name: string
  queryName: string
  tableProps: { fields: Fields[] }
}

type Props = {
  data: InvoiceDetailsData | OrderDetailsData
  options: Options
}

const ReturnDetailsPage: FC<Props> = ({ data, options }): ReactElement => {
  const source = useCallback(() => {
    return { items: data.orderItems }
  }, [data])

  const [openReturnModal, setOpenReturnModal] = useState(false)
  const [returnModalData, setReturnModalData] = useState({})
  const [returnModalItemsData, setReturnModalItemsData] = useState([])
  const { setTableProps, tableProps } = useDataGridStore()
  const [multiReturn, setMultiReturn] = useState<MultiReturn>({ item: [] })
  const [isIncorrectPriceItem, setIsIncorrectPriceItem] =
    useState<boolean>(false)

  useEffect(() => {
    window.localStorage.removeItem(REASONS_KEY)
  }, [])

  const schema = yup
    .object({
      link: yup
        .string()
        .required('Link should be set')
        .test('is-link', VALID_LINK_ALERT, (val: any) => {
          if (val !== undefined) {
            return LINK_PATTERN.test(val)
          }
          return true
        })
        .min(75, 'Link must contain at least 75 characters')
        .max(200, 'Link can contain at most 200 characters'),
    })
    .required()

  const {
    control,
    trigger,
    formState: { isValid },
    getValues,
  } = useForm({
    defaultValues: {
      link: '',
    },
    mode: 'all',
    resolver: yupResolver(schema),
  })

  const handleReturnModal = useCallback(() => {
    const returnData = Object.values(multiReturn).reduce(
      (acc, item) => acc.concat(item),
      []
    )

    setOpenReturnModal((prevState) => {
      if (!prevState) {
        setReturnModalData(data)
        setReturnModalItemsData(returnData)
      }
      return !prevState
    })
  }, [multiReturn])

  const updateReturnData = (row: UpdateReturnData, data: ReturnItems[]) => {
    setMultiReturn((prev) => {
      return {
        ...prev,
        [(row.itemNo, row.lineNumber)]: data.map(
          ({
            reason,
            quantity,
            extendedAmount,
            invoicedPrice,
            quotedPrice,
          }) => {
            return {
              reason,
              quantity:
                reason === INCORRECT_PRICE
                  ? row.availableReturnQuantity
                  : quantity,
              extendedAmount,
              invoicedPrice,
              quotedPrice,
              productId: row.itemNo,
              lineNumber: row.lineNumber,
            }
          }
        ),
      }
    })
  }

  const actions = [
    {
      header: () => {
        return <ReturnDetailInputHeader />
      },
      render: (row: UpdateReturnData, index: number) => (
        <ReturnDetailInput
          row={row}
          rowIndex={index}
          updateData={updateReturnData.bind(this, row)}
          isIncorrectPriceChosen={isIncorrectPriceItem}
        />
      ),
    },
  ]

  useEffect(() => {
    setTableProps({
      name: `${options.queryName}-${options.id}`,
      source,
      actions,
      ...options.tableProps,
    })
  }, [source, data, options, setTableProps, multiReturn])

  const canReturn = useMemo(() => {
    trigger('link')

    const savedReasons = getArrayFromLocalStorage(REASONS_KEY) || []
    setIsIncorrectPriceItem(
      savedReasons.some((item: any[]) => item?.includes(INCORRECT_PRICE))
    )

    // Note: no need to reduce available quantity for creating the return in case of incorrect price but link should be there
    if (isIncorrectPriceItem) {
      return isValid
    } else {
      return (
        Object.values(multiReturn).reduce((acc, item) => {
          return acc + item.reduce((v, i: any) => v + Number(i.quantity), 0)
        }, 0) > 0
      )
    }
  }, [multiReturn, isValid, isIncorrectPriceItem])

  const closeModal = () => {
    setOpenReturnModal((prev) => {
      return !prev
    })
  }

  return (
    <>
      <Box sx={{ px: { xs: 2, md: 3 } }}>
        <Grid container mt={3}>
          <Grid item xs={10}>
            <BackButton />
          </Grid>
        </Grid>
        {!!data ? (
          <>
            <Box mt={3}>
              <Typography
                variant="h1"
                sx={{
                  color: (theme) => theme.palette.text.secondary,
                  fontWeight: 'bold',
                  marginBottom: 3,
                }}
              >
                {options.name}
              </Typography>
              <Grid
                container
                sx={{
                  borderRadius: '4px',
                  padding: '5px 16px',
                  margin: '20px 0px',
                  backgroundColor: acrColorCyanWhite,
                  justifyContent: 'start',
                  gap: '10px',
                }}
              >
                <Typography
                  variant="h4"
                  sx={{ mr: 7, color: acrColorGrey, fontWeight: 500 }}
                >
                  {ORDER_NUMBER}
                  <Typography
                    variant="caption"
                    sx={{ fontWeight: 'bold', fontSize: '17px', pl: 3 }}
                  >
                    {data.orderNumber}
                  </Typography>
                </Typography>
                <Typography
                  variant="h4"
                  sx={{ mr: 3, color: acrColorGrey, fontWeight: 500 }}
                >
                  {INVOICE_NUMBER}
                  <Typography
                    variant="caption"
                    sx={{ fontWeight: 'bold', fontSize: '17px', pl: 3 }}
                  >
                    {data.invoiceNumber}
                  </Typography>
                </Typography>
              </Grid>
              <Box sx={{ marginBottom: '20px' }}>
                {Object.keys(tableProps).length > 0 && <DataGrid />}
              </Box>
              {isIncorrectPriceItem && (
                <Grid
                  container
                  sx={{ backgroundColor: acrColorCyanWhite }}
                  mb={2}
                >
                  <Grid item sx={{ flex: 1 }} px={2} mt={2}>
                    <NoGridStyledTextField
                      label={LINK}
                      type={'text'}
                      name="link"
                      control={control}
                      className="link"
                    />
                  </Grid>
                </Grid>
              )}
            </Box>
            <Box className="bottom-buttons-wrapper">
              <Button
                disabled={!canReturn}
                variant={'contained'}
                onClick={handleReturnModal}
                className="action-button"
              >
                {CONTINUE}
              </Button>
            </Box>
          </>
        ) : (
          <NoDataFound />
        )}
      </Box>
      <CustomModal
        open={openReturnModal}
        setOpen={setOpenReturnModal}
        sx={{ width: '50%' }}
      >
        <ReturnModalBody
          orderInfo={returnModalData}
          returnItems={returnModalItemsData}
          closeModal={closeModal}
          isIncorrectPriceItem={isIncorrectPriceItem}
          zendeskTicketLink={getValues('link')}
        />
      </CustomModal>
    </>
  )
}
// eslint-disable-next-line react/display-name
export default (props: Props) => (
  <DataGridProvider>
    <ReturnDetailsPage {...props} />
  </DataGridProvider>
)
