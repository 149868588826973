import { FC, ReactElement, useContext, useEffect, useMemo } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import * as yup from 'yup'

import { yupResolver } from '@hookform/resolvers/yup'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import { Button, Grid, InputAdornment, MenuItem } from '@mui/material'
import { useQuery } from '@tanstack/react-query'

import CustomTooltip from '~/components/common/customTooltip/CustomTooltip'
import ControllerStyledSelect from '~/components/Form/StyledSelect'
import { NoGridStyledTextField } from '~/components/Form/StyledTextField'
import { NotificationContext } from '~/components/Notification'
import Loading from '~/components/UI/Loading'
import { useAuth } from '~/services/auth'
import { fetchReturnReasons } from '~/services/returns'
import { ReturnItems, UpdateReturnData } from '~/types/Return.model'
import { REASONS_KEY, TEXTS } from '~/utils/allConstants/Constants'
import {
  getArrayFromLocalStorage,
  insertAtIndexInArray,
  storeArrayInLocalStorage,
} from '~/utils/helpers/helperFunctions'

import './ReturnDetailInput.scss'

const patternTwoDigitsAfterDecimal = /^\d+(\.\d{1,2})?$/
const messageTwoDigitsAfterDecimal =
  'The value must be positive and should have two digits after decimal'

const {
  AVAILABLE_QTY,
  RETURN_QTY,
  REASON_CODE,
  INCORRECT_PRICE,
  INVOICED_PRICE,
  QUOTED_PRICE,
  EXTENDED_AMOUNT,
} = TEXTS

type Props = {
  updateData: (data: ReturnItems[] | []) => void
  row: UpdateReturnData
  rowIndex: number
  isIncorrectPriceChosen?: boolean
}

type InputItemProps = {
  update: (index: number, item: ReturnItems) => void
  remove: (index: number, item: ReturnItems) => void
  index: number
  value: ReturnItems
  isLast: boolean
  remainingItems: number
  fields: ReturnItems[]
  invoicedPrice?: number
  availableQuantity?: number
  isIncorrectPriceChosen?: boolean
}

const ReturnDetailInput: FC<Props> = ({
  row,
  rowIndex,
  updateData,
  isIncorrectPriceChosen,
}): ReactElement => {
  const alert = useContext(NotificationContext)
  const { control, watch } = useForm({
    defaultValues: {
      return: [
        {
          reason: '',
          quantity: '',
        },
      ],
    },
  })
  const { fields, append, update, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'return', // unique name for your Field Array
  })

  const updateHandler = (index: number, data: ReturnItems) => {
    const savedReasons = getArrayFromLocalStorage(REASONS_KEY) || []
    if (
      (savedReasons.some(
        (item: any[]) => item?.length && !item.includes(INCORRECT_PRICE)
      ) &&
        data.reason === INCORRECT_PRICE) ||
      (savedReasons.some(
        (item: any[]) => item?.length && item.includes(INCORRECT_PRICE)
      ) &&
        data.reason !== INCORRECT_PRICE)
    ) {
      alert.error(
        'Incorrect Pricing cannot be combined with other reason codes'
      )
      update(index, {
        reason: '',
        quantity: '',
      })
    } else {
      insertAtIndexInArray(savedReasons, rowIndex, data.reason)
      storeArrayInLocalStorage(REASONS_KEY, savedReasons)

      update(index, data)
      append({
        reason: '',
        quantity: '',
      })
    }
  }

  const removeHandler = (index: number, data: ReturnItems) => {
    const savedReasons = getArrayFromLocalStorage(REASONS_KEY) || []
    const updatedReasons = savedReasons.map((item: any[], index: number) => {
      if (rowIndex === index) {
        return item.filter((value) => value !== data.reason)
      }
      return item
    })

    storeArrayInLocalStorage(REASONS_KEY, updatedReasons)

    remove(index)
  }

  const returnWatch = watch('return')

  const remainingItems = useMemo(() => {
    return (
      row.availableReturnQuantity -
      returnWatch.reduce((acc, i) => acc + Number(i.quantity), 0)
    )
  }, [returnWatch])

  useEffect(() => {
    updateData(
      returnWatch.filter((item) => item.quantity !== '' && item.reason !== '')
    )
  }, [returnWatch])

  return (
    <>
      {fields.map((field, index, array) => {
        return (
          <InputItem
            key={field.id}
            index={index}
            value={field}
            update={updateHandler}
            remove={removeHandler}
            isLast={array.length === index + 1}
            remainingItems={remainingItems}
            fields={array}
            invoicedPrice={row.price}
            availableQuantity={row.availableReturnQuantity}
            isIncorrectPriceChosen={isIncorrectPriceChosen}
          />
        )
      })}
    </>
  )
}

const ReturnDetailInputHeader = () => {
  return (
    <Grid container spacing={2} sx={{ minWidth: 690, alignItems: 'center' }}>
      <Grid item sx={{ minWidth: 120 }}>
        {AVAILABLE_QTY}
      </Grid>
      <Grid item sx={{ flex: 1, minWidth: 490 }}>
        <Grid container sx={{ flex: 1, gap: 2 }}>
          <Grid item sx={{ flex: 1 }}>
            {RETURN_QTY}
          </Grid>
          <Grid item sx={{ flex: 1 }}>
            {REASON_CODE}
          </Grid>
        </Grid>
      </Grid>
      <Grid item sx={{ width: 80, alignSelf: 'center' }}>
        &nbsp;
      </Grid>
    </Grid>
  )
}

const InputItem: FC<InputItemProps> = ({
  update,
  remove,
  index,
  value,
  isLast,
  remainingItems,
  fields,
  invoicedPrice,
  availableQuantity,
  isIncorrectPriceChosen,
}) => {
  const { data: user } = useAuth()
  const schema = yup
    .object({
      reason: yup.string().default('').required('Reason should be set'),
      quantity: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .nullable()
        .integer('Qty must be a integer')
        .min(1, 'Qty must be greater than 1')
        .max(
          Number(remainingItems),
          `Qty must be less than or equal to ${remainingItems}`
        )
        .required('Qty should be set'),
      invoicedPrice: yup.number().when('reason', {
        is: (val: any) => val.includes(INCORRECT_PRICE),
        then: () =>
          yup.number().nullable().required('Invoiced price should be set'),
        otherwise: () => yup.number().optional(),
      }),
      quotedPrice: yup.number().when('reason', {
        is: (val: any) => val.includes(INCORRECT_PRICE),
        then: () =>
          yup
            .number()
            .transform((value) => (isNaN(value) ? undefined : value))
            .nullable()
            .test('is-decimal', messageTwoDigitsAfterDecimal, (val: any) => {
              if (val !== undefined) {
                return patternTwoDigitsAfterDecimal.test(val)
              }
              return true
            })
            .min(1, 'Quoted price must be greater than 1')
            .required('Quoted Price is required'),
        otherwise: () =>
          yup
            .number()
            .optional()
            .transform((value) => (isNaN(value) ? undefined : value))
            .nullable(),
      }),
      extendedAmount: yup.number().when('reason', {
        is: (val: any) => val.includes(INCORRECT_PRICE),
        then: () => yup.number().required('Extended amount should be set'),
        otherwise: () => yup.number().optional(),
      }),
    })
    .required()

  const { control, handleSubmit, watch, setValue, setError } = useForm({
    defaultValues: value,
    mode: 'onChange',
    resolver: yupResolver(schema),
  })
  const selectedReason = watch('reason')
  const showPricingFields = selectedReason.includes(INCORRECT_PRICE)
  const returnQuantity = watch('quantity')
  const quotedPrice = watch('quotedPrice')

  const handleAdd = (currentData: ReturnItems) => {
    // Note: do not reduce quantity in case of Incorrect Price
    if (showPricingFields) {
      currentData.quantity = 0
    }
    update(index, currentData)
  }

  const handleRemove = (data: ReturnItems) => {
    remove(index, data)
  }

  const chosenReasons = useMemo(() => {
    return fields
      .reduce((acc: string[], i) => {
        acc.push(i.reason)
        return acc
      }, [])
      .filter((i: string) => i !== '')
  }, [fields])

  const { data: returnReasonCodes, isLoading } = useQuery(
    ['return-reason-code'],
    () => fetchReturnReasons(user.role)
  )
  // Note: user story 3834 - hiding in prod
  delete returnReasonCodes?.['ICP']

  useEffect(() => {
    if (showPricingFields) {
      setValue('quantity', availableQuantity)
      setError('quantity', undefined)
      setValue('invoicedPrice', invoicedPrice)
      const extAmount =
        quotedPrice > 0 && returnQuantity > 0
          ? Number(
              (Math.abs(invoicedPrice - quotedPrice) * returnQuantity).toFixed(
                2
              )
            )
          : 0
      setValue('extendedAmount', extAmount)
    }
  }, [invoicedPrice, quotedPrice, returnQuantity, showPricingFields])

  if (isLoading) {
    return <Loading />
  }

  // Note: no more item addition required in case of Incorrect Price selection
  if (isIncorrectPriceChosen && index === 1) {
    return null
  }

  return (
    <Grid
      container
      spacing={2}
      className="content-wrapper"
      sx={{ minWidth: 690, alignItems: 'center' }}
    >
      <Grid item sx={{ minWidth: 120, alignSelf: 'center' }}>
        {index === 0 && (
          <div
            style={{
              color: remainingItems < 0 ? '#d32f2f' : '#000',
            }}
          >
            {remainingItems}
          </div>
        )}
      </Grid>
      <Grid item sx={{ flex: 1, minWidth: 490 }}>
        <Grid container sx={{ flex: 1, gap: 2 }}>
          <Grid item sx={{ flex: 1 }}>
            <NoGridStyledTextField
              type={'number'}
              name="quantity"
              control={control}
              placeholder="Return Qty"
              disabled={!isLast || showPricingFields}
            />
          </Grid>
          <Grid item sx={{ flex: 1 }}>
            <ControllerStyledSelect
              name={'reason'}
              control={control}
              disabled={!isLast}
              className="margin-none"
              placeholder="Please select reason"
            >
              {Object.keys(returnReasonCodes).map((key) => {
                const itemValue =
                  returnReasonCodes[key as keyof typeof returnReasonCodes]
                const savedReasons = getArrayFromLocalStorage(REASONS_KEY) || []

                return (
                  <MenuItem
                    key={key}
                    value={itemValue}
                    disabled={
                      chosenReasons.includes(itemValue) ||
                      (savedReasons.some(
                        (item: any[]) =>
                          item?.length && !item.includes(INCORRECT_PRICE)
                      ) &&
                        itemValue === INCORRECT_PRICE) ||
                      (savedReasons.some(
                        (item: any[]) =>
                          item?.length && item.includes(INCORRECT_PRICE)
                      ) &&
                        itemValue !== INCORRECT_PRICE)
                    }
                  >
                    {itemValue}
                  </MenuItem>
                )
              })}
            </ControllerStyledSelect>
          </Grid>
        </Grid>
        {showPricingFields && (
          <Grid container sx={{ flex: 1, gap: 2, mt: 2 }}>
            <Grid item sx={{ flex: 1 }}>
              <NoGridStyledTextField
                label={INVOICED_PRICE}
                type={'number'}
                name="invoicedPrice"
                control={control}
                startAdornment={
                  <InputAdornment position="start">$</InputAdornment>
                }
                disabled
              />
            </Grid>
            <Grid item sx={{ flex: 1 }}>
              <NoGridStyledTextField
                label={QUOTED_PRICE}
                type={'number'}
                name="quotedPrice"
                control={control}
                startAdornment={
                  <InputAdornment position="start">$</InputAdornment>
                }
                disabled={!isLast}
              />
            </Grid>
            <Grid item sx={{ flex: 1 }}>
              <NoGridStyledTextField
                label={EXTENDED_AMOUNT}
                type={'number'}
                name="extendedAmount"
                control={control}
                startAdornment={
                  <InputAdornment position="start">$</InputAdornment>
                }
                disabled
              />
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid item sx={{ width: 80, alignSelf: 'center' }}>
        {isLast ? (
          <CustomTooltip title="Click + to add selections to return request">
            <Button onClick={handleSubmit(handleAdd)}>
              <AddCircleOutlineIcon />
            </Button>
          </CustomTooltip>
        ) : (
          <Button onClick={() => handleRemove(value)}>
            <CancelOutlinedIcon />
          </Button>
        )}
      </Grid>
    </Grid>
  )
}

export { ReturnDetailInputHeader }

export default ReturnDetailInput
