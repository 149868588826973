import { Box } from '@mui/material'

import { TEXTS } from '~/utils/allConstants/Constants'

const { NO_DATA_FOUND } = TEXTS

const NoDataFound = () => {
  return (
    <Box
      sx={{
        minHeight: '10rem',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {NO_DATA_FOUND}
    </Box>
  )
}

export default NoDataFound
