import { memo, useEffect, useState } from 'react'
import { NavLink, useParams } from 'react-router-dom'

import { Button } from '@mui/material'

import ContentWrapper from '~/components/UI/ContentWrapper/ContentWrapper'
import useDataGridStore, {
  DataGridProvider,
} from '~/components/UI/DataGrid/DataGridContext'
import TabsTablePage from '~/components/UI/TabsTablePage'
import { useAssignToMeMutation } from '~/services/approval'
import { fetchReturns } from '~/services/returns'
import { ReturnRequestApproval } from '~/types/Approval.model'
import { TabsType } from '~/types/TabsType.model'
import { APPROVAL_STATUSES, TEXTS } from '~/utils/allConstants/Constants'

import { allReturnsFields } from '../components/ApprovalTabContent'
import { UnAssignButton } from '../components/UnAssignButton'

const { NO_DATA_MESSAGE, ASSIGN_TO_ME, ALL_OPEN, ALL_CLOSED } = TEXTS
const { APPROVED, REJECTED, PENDING, REVIEWED, CLOSED } = APPROVAL_STATUSES

const Returns = () => {
  const { active_tab } = useParams()
  const assignToMeMutation = useAssignToMeMutation()
  const currentTab = active_tab || 'open'
  const { setTableProps, refetch } = useDataGridStore()
  const [unassignSuccess, setUnassignSuccess] = useState(null)
  useEffect(() => {
    refetch()
    setTableProps((prevState: TabsType) => {
      return {
        ...prevState,
        additionalFilters: {
          q: prevState?.additionalFilters.q,
        },
      }
    })
  }, [unassignSuccess])

  const handleAssignToMe = (row: ReturnRequestApproval) => {
    assignToMeMutation.mutate(row.id)
  }

  const actions = [
    {
      render: (row: ReturnRequestApproval) => {
        return (
          <>
            <Button
              variant={'contained'}
              component={NavLink}
              className="action-button"
              to={
                row.approvalStatus === PENDING && row.isCarrierReturn === null
                  ? `${row.id}/review-details`
                  : `${row.id}/approve-details`
              }
              disabled={row.assignmentDetails.assignableToCurrentUser === false}
              onClick={() => handleAssignToMe(row)}
            >
              {ASSIGN_TO_ME}
            </Button>
            <UnAssignButton row={row} setUnassignSuccess={setUnassignSuccess} />
          </>
        )
      },
    },
  ]
  const defaultProps = {
    source: fetchReturns,
    fields: allReturnsFields,
    notFoundMessage: NO_DATA_MESSAGE('Returns'),
  }
  const params: TabsType[] = [
    {
      label: ALL_OPEN,
      name: 'open-returns',
      ...defaultProps,
      actions,
      additionalFilters: {
        q: {
          approvalStatus: [PENDING, REVIEWED, APPROVED],
        },
      },
    },
    {
      label: ALL_CLOSED,
      name: 'closed-returns',
      ...defaultProps,
      additionalFilters: {
        q: { approvalStatus: [REJECTED, CLOSED] },
      },
    },
  ]

  return (
    <ContentWrapper>
      <TabsTablePage currentTab={currentTab} tabs={params} />
    </ContentWrapper>
  )
}
// eslint-disable-next-line react/display-name
export default memo(() => (
  <DataGridProvider>
    <Returns />
  </DataGridProvider>
))
