import { useContext } from 'react'
import { useParams } from 'react-router'

import { useQueries } from '@tanstack/react-query'

import { fetchAccountById, fetchAccountMetadata } from '~/services/accounts'
import { Account, SubAccount } from '~/types/Account.model'

import Loading from '../UI/Loading'

import { AccountsContext } from './index'

const AccountLoader = ({ children }: any) => {
  const params = useParams()
  const accountsContext = useContext(AccountsContext)

  const queries = []
  if (!!params.accountId) {
    queries.push({
      queryKey: ['accounts', params.accountId],
      queryFn: fetchAccountById,
      onerror: () => accountsContext.setUserAccess(false),
      onSuccess: (data: Account) => {
        accountsContext.setSelectedAccount(data)
        accountsContext.setUserAccess(true)
        // Note: assuming after success, user will have access to the account so can fetch metadata
        queries.push({
          queryKey: ['accounts', params.accountId, 'metadata'],
          queryFn: fetchAccountMetadata,
        })
      },
    })
  }
  if (!!params.subAccountId) {
    queries.push({
      queryKey: ['subAccounts', params.subAccountId],
      queryFn: fetchAccountById,
      onSuccess: (data: SubAccount) =>
        accountsContext.setSelectedSubAccount(data),
    })
  }
  const result = useQueries({
    queries,
  })

  if (result.some(({ isFetching }) => isFetching)) {
    return <Loading />
  }

  return children
}

export default AccountLoader
