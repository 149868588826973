import {
  createContext,
  FC,
  ReactElement,
  useCallback,
  useContext,
  useState,
} from 'react'
import {
  JsonParam,
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params'

import { useQuery } from '@tanstack/react-query'

import { applyResponseGrouping } from '~/utils/helpers/helperFunctions'

const DataContext = createContext({} as any)

interface ISourceResponse {
  items: any[]
  totalCount: number
}

interface Props {
  children: ReactElement
  initialPageSize?: number
}

type groupType = {
  id: string
  name: string
  groupType: string
}

const DataGridProvider: FC<Props> = ({ children, initialPageSize = 10 }) => {
  const [query, setQuery] = useQueryParams({
    page: withDefault(NumberParam, 1),
    pageSize: withDefault(StringParam, initialPageSize.toString()),
    sort: withDefault(StringParam, ''),
    groupBy: withDefault(StringParam, ''),
    groupsApplied: withDefault(JsonParam, null),
    filters: withDefault(JsonParam, {}),
  })

  const { page, pageSize, sort, groupsApplied, filters, groupBy } = query

  const [tableProps, setTableProps] = useState<any>({})
  const [openFilterModal, setOpenFilterModal] = useState(false)

  const { data, isError, isFetching, refetch } = useQuery<ISourceResponse>(
    [
      tableProps.name,
      {
        page,
        pageSize,
        ...tableProps.additionalFilters,
        filters,
        sort: sort !== '' ? sort : undefined,
        groupBy: groupBy !== '' ? groupBy : undefined,
      },
    ],
    async () => {
      if (tableProps.source) {
        const data = await tableProps.source({
          page,
          pageSize,
          ...tableProps.additionalFilters,
          // additionalFilters: tableProps.additionalFilters,
          filters,
          sort: sort !== '' ? sort : undefined,
          groupBy: groupBy !== '' ? groupBy : undefined,
          fields: tableProps.fields,
        })
        if (groupsApplied && groupsApplied.length > 0) {
          return applyResponseGrouping(data, groupsApplied)
        } else {
          return data
        }
      } else {
        return {
          items: [],
          totalCount: 0,
        }
      }
    },
    {
      placeholderData: {
        items: [],
        totalCount: 0,
      },
      keepPreviousData: true,
    }
  )

  const reset = () => {
    setQuery({
      page: 1,
      sort: '',
      groupBy: '',
      filters: {},
      groupsApplied: null,
    })
  }

  const changePageSize = useCallback((pageSize: string) => {
    setQuery({
      pageSize: pageSize,
      page: 1,
    })
  }, [])

  const applyCellSort = useCallback(
    (cellId: string) => {
      let localSort = ''
      const [id, direction] = sort.split(':')
      if (sort !== '') {
        if (id === cellId) {
          if (direction === 'asc') {
            localSort = `${cellId}:desc`
          }
        } else {
          localSort = `${cellId}:asc`
        }
      } else {
        localSort = `${cellId}:asc`
      }

      setQuery({
        sort: localSort,
      })
    },
    [sort]
  )
  const applyGrouping = useCallback(
    (cellId: string, cellName: string, groupType: string) => {
      let group = {}

      if (groupsApplied && groupsApplied.length > 0) {
        const foundGroupIndex = groupsApplied.findIndex(
          (group: groupType) => group.id === cellId
        )
        if (foundGroupIndex > -1) {
          groupsApplied.splice(foundGroupIndex, 1)
        } else {
          groupsApplied.push({ id: cellId, name: cellName, groupType })
        }
        group = {
          groupBy: groupsApplied.map((group: groupType) => group.id).join(','),
          groupsApplied,
        }
      } else {
        group = {
          groupBy: cellId,
          groupsApplied: [{ id: cellId, name: cellName, groupType }],
        }
      }

      setQuery(group)
    },
    [setQuery, groupsApplied]
  )
  const getIsGroupActive = useCallback(
    (groupId: string) => {
      return (
        groupsApplied &&
        groupsApplied.find((group: groupType) => groupId === group.id)
      )
    },
    [groupsApplied]
  )

  const getIsCellActive = useCallback(
    (cellId: string) => {
      if (sort !== '') {
        return cellId === sort.split(':')[0]
      } else {
        return false
      }
    },
    [sort]
  )

  const getSortingDirection = useCallback(
    (cellId: string) => {
      if (sort !== '' && cellId === sort.split(':')[0]) {
        return sort.split(':')[1]
      } else {
        return 'asc'
      }
    },
    [sort]
  )

  const changeFilters = useCallback((data: any) => {
    setQuery({
      page: 1,
      filters: data,
    })
  }, [])

  const clearFilters = useCallback(() => {
    setQuery({
      page: 1,
      filters: {},
    })
  }, [])

  const openFilter = useCallback(() => setOpenFilterModal(true), [])

  return (
    <DataContext.Provider
      value={{
        tableProps,
        setTableProps,
        refetch,
        page,
        setPage: (page: number) => setQuery({ page }),
        pageSize,
        sort,
        openFilterModal,
        setOpenFilterModal,
        openFilter,
        filters,
        clearFilters,
        data,
        isFetching,
        isError,
        getIsCellActive,
        getIsGroupActive,
        applyGrouping,
        getSortingDirection,
        applyCellSort,
        changeFilters,
        changePageSize,
        reset,
      }}
    >
      {children}
    </DataContext.Provider>
  )
}

export { DataGridProvider }

const useDataGridStore = () => {
  return useContext(DataContext)
}

export default useDataGridStore
