import { memo, useContext, useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'

import { Box } from '@mui/material'

import { useAuth } from '~/services/auth'
import { fetchReturns } from '~/services/returns'
import { RoleTypes } from '~/services/users'
import { Account, SubAccount } from '~/types/Account.model'
import { TabsType } from '~/types/TabsType.model'
import { APPROVAL_STATUSES, TEXTS } from '~/utils/allConstants/Constants'

import { AccountsContext } from '../Accounts'
import useSynchAccountsNavigation from '../Accounts/hook/useSynchAccountsNavigation'
import useDataGridStore, {
  DataGridProvider,
} from '../UI/DataGrid/DataGridContext'
import TabsTablePage from '../UI/TabsTablePage'

import { fields as openFields } from './components/OpenReturnTabContent'
import { fields as historyFields } from './components/ReturnHistoryTabContent'

const { NO_DATA_MESSAGE, OPEN_RETURNS, RETURNS_HISTORY } = TEXTS
const { APPROVED, REJECTED, PENDING, REVIEWED, CLOSED } = APPROVAL_STATUSES

const Returns = () => {
  const { active_tab } = useParams()
  const { data: user } = useAuth()
  const currentTab = active_tab || 'open'
  const { setTableProps } = useDataGridStore()
  const { selectedAccount, selectedSubAccount } =
    useContext(AccountsContext) ||
    ({} as {
      selectedAccount: Account
      selectedSubAccount: SubAccount
    })

  useSynchAccountsNavigation(`returns/${currentTab}`)

  const filters = useMemo(() => {
    const f = {} as { customerNumber: string; shipToNumber: string }
    if (selectedAccount) {
      f.customerNumber = selectedAccount.customerNumber

      if (selectedSubAccount) {
        f.shipToNumber = selectedSubAccount.shipToNumber
      }
    }
    return f
  }, [selectedAccount, selectedSubAccount])

  const updateQ = [RoleTypes.CARRIER].includes(user.role)
    ? { 'submitter.email': user.contactEmail }
    : ''

  useEffect(() => {
    setTableProps((prevState: TabsType) => {
      return {
        ...prevState,
        additionalFilters: {
          q: {
            approvalStatus: prevState?.additionalFilters.q.approvalStatus,
            accountFilters: filters,
            ...updateQ,
          },
        },
      }
    })
  }, [filters, setTableProps])

  const defaultProps = {
    source: fetchReturns,
    notFoundMessage: NO_DATA_MESSAGE('Returns'),
  }

  const openFieldIndex = openFields.findIndex(
    (item) => item.id === 'orderNumber'
  )

  const historyFieldIndex = historyFields.findIndex(
    (item) => item.id === 'orderNumber'
  )

  if ([RoleTypes.CARRIER].includes(user.role)) {
    openFields[openFieldIndex].format = (_, row: string | any) => {
      return row.orderNumber
    }
    historyFields[historyFieldIndex].format = (_, row: string | any) => {
      return row.orderNumber
    }
  }

  const params: TabsType[] = [
    {
      label: OPEN_RETURNS,
      name: 'open-returns',
      ...defaultProps,
      fields: [RoleTypes.CARRIER].includes(user.role)
        ? openFields.filter((field) => field.label !== 'Return Value $')
        : openFields,
      additionalFilters: {
        q: {
          approvalStatus: [PENDING, APPROVED, REVIEWED],
          accountFilters: filters,
          ...updateQ,
        },
      },
    },
    {
      label: RETURNS_HISTORY,
      name: 'returns-history',
      ...defaultProps,
      fields: [RoleTypes.CARRIER].includes(user.role)
        ? historyFields.filter((field) => field.label !== 'Return Value $')
        : historyFields,
      additionalFilters: {
        q: {
          approvalStatus: [REJECTED, CLOSED],
          accountFilters: filters,
          ...updateQ,
        },
      },
    },
  ]

  return (
    <Box className="content-spacing">
      <TabsTablePage currentTab={currentTab} tabs={params} />
    </Box>
  )
}
// eslint-disable-next-line react/display-name
export default memo(() => (
  <DataGridProvider>
    <Returns />
  </DataGridProvider>
))
