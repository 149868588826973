import { useParams } from 'react-router-dom'

import { Box } from '@mui/material'
import { useQuery } from '@tanstack/react-query'

import { fetchReturnDetailsData } from '~/services/returns'

import BackButton from '../common/backButton/BackButton'
import NoDataFound from '../common/noDataFound/NoDataFound'
import { GlobalLoading } from '../UI/Loading'

import { commonFields } from './components/ReturnDetailInfoContent'
import ReturnDetailsInfoPage from './components/ReturnDetailsInfoPage'

const options = {
  name: 'Return Item Details',
  queryName: 'return-info-details',
  tableProps: {
    fields: commonFields,
  },
}

const ReturnDetailInfo = () => {
  const { id } = useParams<{ id?: string }>()

  const { isLoading, data } = useQuery(
    [`return-info-details`, id],
    () => {
      return fetchReturnDetailsData(id)
    },
    { keepPreviousData: true }
  )

  if (isLoading) {
    return <GlobalLoading />
  }

  return (
    <>
      {!!data ? (
        <ReturnDetailsInfoPage data={data} options={{ ...options, id }} />
      ) : (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          <Box
            className="action-buttons-wrapper"
            sx={{ justifyContent: 'flex-start', mt: 3 }}
          >
            <Box sx={{ px: { xs: 2, md: 3 } }}>
              <BackButton />
            </Box>
          </Box>
          <NoDataFound />
        </Box>
      )}
    </>
  )
}

export default ReturnDetailInfo
