import {
  FC,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import ReactGA from 'react-ga4'
import InfiniteScroll from 'react-infinite-scroll-component'
import {
  JsonParam,
  NumberParam,
  useQueryParams,
  withDefault,
} from 'use-query-params'

import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff'
import { LoadingButton } from '@mui/lab'
import {
  Badge,
  Box,
  Button,
  CircularProgress,
  Collapse,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { useInfiniteQuery } from '@tanstack/react-query'

import { useAccountMetadata } from '~/services/accounts'
import {
  exportProdcutsHistoryFile,
  fetchProductsPriceHistory,
} from '~/services/reports'
import { theme } from '~/theme/AppTheme'
import { Account, SubAccount } from '~/types/Account.model'
import { Fields } from '~/types/Fields.model'
import { Items } from '~/types/Reports.model'
import FilterIcon from '~/UI/icons/FilterIcon'
import CustomModal from '~/UI/modals/CustomModal'
import NoDataFoundModal from '~/UI/modals/NoDataFoundModal'
import { MENUS, TEXTS } from '~/utils/allConstants/Constants'
import { TableCellFormat } from '~/utils/helpers/helperFunctions'

import { AccountsContext } from '../Accounts'
import useSynchAccountsNavigation from '../Accounts/hook/useSynchAccountsNavigation'
import NoDataFound from '../common/noDataFound/NoDataFound'
import ContentWrapper from '../UI/ContentWrapper/ContentWrapper'
import { GlobalLoading } from '../UI/Loading'

import InfiniteDataGridFilters from './components/InfiniteDataGridFilters'
import { fields, subFields } from './components/PriceChangeHistoryContent'

import variables from '~/theme/scss/_variables.module.scss'

const {
  acrColorLightGrey2,
  acrColorRedishGrey,
  acrColorMediumGrey,
  acrColorCyanWhite,
} = variables
const { EXPORT_TO_PDF, EXPORT_TO_EXCEL, EXPORT_AS_PDF, EXPORT_AS_EXCEL } = TEXTS
const { PRICE_CHANGE_HISTORY } = MENUS

const Row: FC<{
  row: Items
  isOpen: boolean
  updateOpenRow: (id: string) => void
}> = ({ row, isOpen, updateOpenRow }): ReactElement => {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    setOpen(isOpen)
    if (isOpen) {
      updateOpenRow(row.productId)
    }
  }, [isOpen])

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
    updateOpenRow(row.productId)
  }

  return (
    <>
      <TableRow sx={{ height: 40 }}>
        {fields.map((column) => {
          return (
            <TableCell
              key={column.id}
              scope="row"
              sx={{ textAlign: 'center' }}
              padding="none"
            >
              <TableCellFormat column={column} row={row} />
            </TableCell>
          )
        })}

        <TableCell sx={{ textAlign: 'center' }} padding="none">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={handleToggle}
          >
            {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow sx={{ height: 0 }}>
        <TableCell
          sx={{
            paddingBottom: 0,
            paddingTop: 0,
            backgroundColor: acrColorCyanWhite,
            boxShadow: 'inset 0px 0px 5px 3px rgba(0, 0, 0, 0.05)',
          }}
          colSpan={6}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                History
              </Typography>
              <TableContainer
                sx={{
                  mt: 2,
                  border: '1px solid',
                  borderColor: acrColorRedishGrey,
                  mb: 2,
                  maxHeight: 250,
                }}
                component={Paper}
              >
                <Table size="small" aria-label="history">
                  <TableHead>
                    <TableRow sx={{ height: 40 }}>
                      {subFields.map((column) => (
                        <TableCell
                          component="th"
                          scope="column"
                          key={column.id}
                          sx={{
                            borderBottom: '2px solid',
                            borderBottomColor: acrColorLightGrey2,
                          }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.priceHistoryList.map((column) => (
                      <TableRow key={column.id} sx={{ height: 40 }}>
                        {subFields.map((row) => (
                          <TableCell key={row.id} scope="row">
                            <TableCellFormat column={row} row={column} />
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

const PriceChangeHistory = () => {
  const [openAll, setOpenAll] = useState(false)
  const [openRowIds, setOpenRowIds] = useState([])
  const [isPdfLoading, setIsPdfLoading] = useState(false)
  const [isXlsxLoading, setIsXlsxLoading] = useState(false)
  const isMatch = useMediaQuery(theme.breakpoints.down('sm'))
  const [query, setQuery] = useQueryParams({
    page: withDefault(NumberParam, 1),
    pageSize: withDefault(NumberParam, 100),
    filters: withDefault(JsonParam, {}),
  })
  const { page, pageSize, filters } = query

  const changeFilters = useCallback((data: any) => {
    setQuery({
      page: 1,
      filters: data,
    })
  }, [])

  const clearFilters = useCallback(() => {
    setQuery({
      page: 1,
      filters: {},
    })
  }, [])

  const [openFilterModal, setOpenFilterModal] = useState(false)
  const openFilter = () => setOpenFilterModal(true)
  const checkFilterFieldConfiguration = fields?.some(
    (field: Fields) => !!field?.filterFieldConfiguration
  )

  const { selectedAccount, selectedSubAccount } =
    useContext(AccountsContext) ||
    ({} as {
      selectedAccount: Account
      selectedSubAccount: SubAccount
    })

  const { data: accountMetadata } = useAccountMetadata()

  const additionalFilters = useMemo(() => {
    const f = {} as { accountId: string }
    if (selectedAccount) {
      f.accountId = selectedAccount.id

      if (selectedSubAccount) {
        f.accountId = selectedSubAccount.id
      }
    }
    return f
  }, [selectedAccount, selectedSubAccount])

  const enabledAPI = () => {
    if (selectedAccount != undefined) {
      if (selectedAccount.subAccountHasPriceGroup) {
        return selectedSubAccount != undefined
      } else {
        return true
      }
    } else {
      return false
    }
  }

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isRefetching } =
    useInfiniteQuery(
      [
        `price_change_history`,
        { additionalFilters, pageSize, page, filters, fields },
      ],
      ({ pageParam = 1 }) => {
        return fetchProductsPriceHistory({
          ...additionalFilters,
          filters,
          fields,
          page: pageParam,
          pageSize,
        })
      },
      {
        keepPreviousData:
          selectedSubAccount?.customerNumber ===
          selectedAccount?.customerNumber,
        enabled: enabledAPI(),
        getNextPageParam: (lastPage) => {
          if (lastPage.params.page * 100 >= lastPage.data?.totalCount) {
            return false
          }
          return lastPage.params.page + 1
        },
      }
    )

  const handleToggleAll = () => {
    if (openAll) {
      setOpenRowIds([])
    } else {
      setOpenRowIds(content.map((items) => items.productId))
    }
    setOpenAll((prevOpenAll) => !prevOpenAll)
  }

  const handlePdfExport = useCallback(() => {
    setIsPdfLoading(true)
    ReactGA.event({
      category: 'Products',
      action: EXPORT_AS_PDF,
    })
    return exportProdcutsHistoryFile({
      extension: 'PDF',
      accountId: additionalFilters.accountId,
      expandedProductId: openRowIds,
    })
      .then(() => setIsPdfLoading(false))
      .catch(() => setIsPdfLoading(false))
  }, [additionalFilters.accountId, data, openRowIds])

  const handleExcelExport = useCallback(() => {
    setIsXlsxLoading(true)
    ReactGA.event({
      category: 'Products',
      action: EXPORT_AS_EXCEL,
    })
    return exportProdcutsHistoryFile({
      extension: 'XLSX',
      accountId: additionalFilters.accountId,
      expandedProductId: openRowIds,
    })
      .then(() => setIsXlsxLoading(false))
      .catch(() => setIsPdfLoading(false))
  }, [data, additionalFilters.accountId, openRowIds])

  const updateOpenRow = (rowId: string) => {
    const isSelected = openRowIds.includes(rowId)
    if (isSelected) {
      const newArray = openRowIds.filter((id: string) => id !== rowId)
      setOpenRowIds(newArray)
    } else {
      setOpenRowIds([...openRowIds, rowId])
    }
  }

  useSynchAccountsNavigation(`reports/price-change-history`)

  if (
    !selectedSubAccount ||
    (selectedSubAccount && accountMetadata?.priceSetupAt !== 'ACCOUNT')
  ) {
    if (!selectedAccount) {
      return (
        <NoDataFoundModal
          message={'Please select My Account before proceeding'}
        />
      )
    } else if (!selectedSubAccount && selectedAccount.subAccountHasPriceGroup) {
      return (
        <NoDataFoundModal
          message={'Please select Sub-Account before proceeding'}
        />
      )
    }
  }

  if (!data) {
    return <GlobalLoading />
  }

  const content = data.pages.reduce((acc, page) => {
    return [...acc, ...page.data.items]
  }, [])

  return (
    <ContentWrapper>
      <Grid
        container
        direction={isMatch ? 'column-reverse' : 'row'}
        sx={{ gap: 2 }}
      >
        <Typography variant="h3" mt={1} gutterBottom>
          {PRICE_CHANGE_HISTORY}
        </Typography>
        <Box
          className="action-buttons-wrapper"
          sx={{
            flex: 1,
            flexWrap: isMatch ? 'wrap' : 'nowrap',
            minWidth: 180,
          }}
        >
          <LoadingButton
            className="action-button"
            variant={'contained'}
            sx={{
              width: isMatch ? '100%' : 220,
            }}
            disabled={content.length === 0}
            onClick={handlePdfExport}
            loading={isPdfLoading}
          >
            {EXPORT_TO_PDF}
          </LoadingButton>
          <LoadingButton
            className="action-button"
            variant={'contained'}
            sx={{
              width: isMatch ? '100%' : 220,
            }}
            disabled={content.length === 0}
            onClick={handleExcelExport}
            loading={isXlsxLoading}
          >
            {EXPORT_TO_EXCEL}
          </LoadingButton>
        </Box>
      </Grid>
      <Box>
        <InfiniteScroll
          dataLength={content ? content.length : 0}
          next={fetchNextPage}
          hasMore={hasNextPage}
          loader={<></>}
          style={{ overflow: 'hidden' }}
          scrollableTarget="scrollableContainer"
        >
          <TableContainer
            id="scrollableContainer"
            sx={{
              mt: 2,
              border: '1px solid',
              borderColor: acrColorRedishGrey,
              mb: 2,
              height: content.length > 0 ? 400 : 150,
            }}
            component={Paper}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow sx={{ textAlign: 'center' }}>
                  {fields.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                      sx={{
                        borderBottom: '2px solid',
                        borderBottomColor: acrColorLightGrey2,
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                  <TableCell
                    sx={{
                      borderBottom: '2px solid',
                      borderBottomColor: acrColorLightGrey2,
                      textAlign: 'center',
                    }}
                  >
                    <Button
                      onClick={handleToggleAll}
                      disabled={content.length == 0}
                    >
                      {openAll ? 'Collapse All' : 'Expand All'}
                    </Button>
                  </TableCell>

                  {checkFilterFieldConfiguration && (
                    <TableCell
                      className="stickyTableCell"
                      sx={{
                        borderBottom: '2px solid',
                        borderBottomColor: acrColorLightGrey2,
                      }}
                    >
                      <>
                        <IconButton onClick={openFilter}>
                          <Badge
                            badgeContent={Object.keys(filters).length}
                            color={'primary'}
                          >
                            <FilterIcon color={acrColorMediumGrey} />
                          </Badge>
                        </IconButton>

                        {Object.keys(filters).length > 0 && (
                          <IconButton onClick={clearFilters}>
                            <FilterAltOffIcon />
                          </IconButton>
                        )}
                      </>
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {content.map((row: Items) => (
                  <Row
                    key={row.productId}
                    row={row}
                    isOpen={openAll}
                    updateOpenRow={updateOpenRow}
                  />
                ))}
              </TableBody>
              {isFetchingNextPage && (
                <TableRow>
                  <TableCell
                    colSpan={6}
                    sx={{
                      textAlign: 'center',
                      backgroundColor: acrColorCyanWhite,
                    }}
                  >
                    {<CircularProgress />}
                  </TableCell>
                </TableRow>
              )}
            </Table>

            {content.length == 0 && <NoDataFound />}
          </TableContainer>
        </InfiniteScroll>
      </Box>
      {isRefetching && <GlobalLoading />}
      <CustomModal
        open={openFilterModal}
        setOpen={setOpenFilterModal}
        sx={{ width: '50%' }}
      >
        <InfiniteDataGridFilters
          filters={filters}
          changeFilters={changeFilters}
          fields={fields}
        />
      </CustomModal>
    </ContentWrapper>
  )
}

export default PriceChangeHistory
