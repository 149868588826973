import { useNavigate } from 'react-router-dom'

import { ArrowBack } from '@mui/icons-material'
import { Button } from '@mui/material'

import { TEXTS } from '~/utils/allConstants/Constants'

const { BACK } = TEXTS

const BackButton = () => {
  const navigate = useNavigate()
  return (
    <Button
      startIcon={<ArrowBack />}
      sx={{
        fontSize: 20,
        color: (theme) => theme.palette.text.primary,
        px: 0,
        marginRight: 'auto',
      }}
      onClick={() => navigate(-1)}
    >
      {BACK}
    </Button>
  )
}

export default BackButton
